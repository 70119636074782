import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useState } from 'react';

export const Brynn = () => {
  const compliments = [
    "You have really pretty eyes.",
    "Your body is hottttt lol",
    "You make me feel really safe.",
    "You have a great smile :)",
    "You absolutely rip it up on the mountain",
    "You work hard and that is really attractive to me",
    "You take care of yourself. Just wow.",
    "The name Brynn is just really cool. I know that's not your control, but I like it.",
    "You encourage me to work harder and be less lazy.",
    "You are a really good cuddler",
    "Your eyes remind me of a mountainside (stole that line from zach bryan sorry, but it's true)",
    "You do little cute things like skip and be happy that make my heart feel good",
    "You're present when you are with me",
    "You're really good with kids (not that I noticed that or anything...)",
    "You make me giggle a lot",
    "You're chocolate chip cookies are so good (paxton even complimented them)",
    "You have a wonderful sense of adventure",
    "You get up early. Love that.",
    "You laugh at my jokes even when they're so stupid",
    "Your broutfits are great, my type of style lol",
    "I don't know the word for it, but you're hardy. Like you can go with the flow, rough it, and have a good time.",
    "You like type 2 fun and will do cool stuff with me",
    "You make me want to plan adventures with you and make tons of awesome plans"
  ];

  const [complimentText, setComplimentText] = React.useState('');
  const [count, setCount] = React.useState(0);
  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const generateCompliment = () => {
    if (count > 2) {
      setOpen(true);
      return;
    }
    const randomIndex = Math.floor(Math.random() * compliments.length);
    setComplimentText(compliments[randomIndex]);
    setCount(count + 1);
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', height: '100vh' }}>
      <h1>Hi Brynn!</h1>
      <Button variant="contained" color="primary" onClick={generateCompliment}>
        Compliment Me!
      </Button>
      <span style={{ marginTop: '20px' }}>
        {complimentText}
      </span>

      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>{"Slow Down!!"}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Woah, getting greedy? Come back later. There's more I promise.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
