import './App.css';
import React from "react";
import {
  BrowserRouter,
  Routes,
  Route,
  useLocation
} from "react-router-dom";

import { Home } from './pages/Home';
import { Ffeach } from './pages/Ffeach';
import { Playlist } from './pages/Playlist';
import { Highlights } from './pages/Highlights';
import { Brynn } from './pages/Brynn';

// A custom hook that builds on useLocation to parse
// the query string for you.
function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

function App() {
  return (
    <div>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/ffeach" element={<Ffeach />} />
          <Route path="/playlist" element={<Playlist />} />
          <Route path="/highlights" element={<Highlights />} />
          <Route path="/brynn" element={<Brynn />} />
        </Routes>   
      </BrowserRouter>
    </div>  
  );
}

export default App;
